import React, { StrictMode } from 'react';

//IMPORT FONTELLO ICONS
import '../styles/fontello/animation.css';
import '../styles/fontello/fontello.css';
import '../styles/fontello/fontello-codes.css';
import '../styles/fontello/fontello-embedded.css';
import '../styles/fontello/fontello-ie7.css';
import '../styles/fontello/fontello-ie7-codes.css';

import '../constants/dayjs';

//IMPORT REMIX ICONS

import AppInitialScreen from '../components/AppInitialScreen';

//TRANSLATIONS
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from '../translations/en.json';
import es from '../translations/es.json';
import de from '../translations/de.json';

import { Helmet } from 'react-helmet';
import UtilityService from '../services/UtilityService';

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources: {
			en,
			es,
			de,
		},
		lng: 'en',
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
	});

const App = ({ location }) => {
	const meta_data = UtilityService.getPageMetaData(location.pathname);

	return (
		<StrictMode>
			<Helmet>
				{meta_data &&
					meta_data.map((meta_tag) => {
						return <meta key={meta_tag.key} property={meta_tag.name} content={meta_tag.value} />;
					})}
			</Helmet>
			<AppInitialScreen />
		</StrictMode>
	);
};

export default App;
